<template>
  <div class="collect p_lr_05">
    <div class="p_lr_05 flex algin">
      <input type="text" name="" id="" placeholder="课程名称" class="inputSty" v-model="courseName">
      <i class="el-icon-search sousuo pointer" @click="getList"></i>
    </div>
    <div v-loading="loading">
      <div class=" m_t_08 " v-if="courseList.length > 0">
        <div class="flex_col">
          <div class="box">
            <div v-for="(item,index) in courseList" class="card pointer" :key="index">
              <div class="img-box" @click="jump(item.id)">
                <el-image
                    class="image"
                    :src="item.coverUrl"
                ></el-image>
              </div>
              <div class="flex flex_col p_in_04">
                <div class="flex p_in_02 algin w100">
                  <div class="flex1 f25 hidden" style="text-align: left">{{ item.name }}</div>
                  <div class=" flex algin txt_right" @click="cancelColl(item)"
                  >
                    <el-image
                        class="scang"
                        :src="shoucangSelect"

                    ></el-image>
                    <div class="txt_light m_l_02">取消收藏</div>
                  </div>
                </div>
                <div class="flex p_in_02 f20 txt_right">{{ item.introduce.substring(0, item.introduce.indexOf(br)) }}
                </div>
              </div>
            </div>
          </div>
          <el-pagination
              @current-change="changePage"
              background
              :page-size="pageSize"
              :current-page="currentPage"
              layout="prev, pager, next"
              :total="total"
              class="flex algin m_t_08 flex_center w80"
              hide-on-single-page
          >
          </el-pagination>
        </div>
      </div>
      <el-empty v-else description="暂无收藏课程"></el-empty>
    </div>
  </div>

</template>

<script>
import shoucang from '@/assets/img/shoucang.png'
import shoucangSelect from '@/assets/img/shoucang_select.png'
import {cancelCollection_api, myCollection_api} from "@/api/mine";

export default {
  name: "collect",
  data() {
    return {
      shoucang,
      shoucangSelect,
      isActive: 0,
      courseList: [],//我的收藏课程列表
      currentPage: 1, // 当前页码
      pageSize: 8, // 每页条数
      total: 0, // 总条数
      courseName: '',//课程名称
      br: '</br>',
      // 加载
      loading: false,
      statusT: true,
    }
  },
  created() {
    this.getList()
  },
  methods: {
    // 取消收藏
    async collection(item) {
      if (this.statusT == true) {
        this.statusT = false
        await cancelCollection_api({id: item}).then(res => {
          this.statusT = true
          if (res.code == 200) {
            this.getList()
          }
        })
      }
    },
    // 取消收藏确认
    cancelColl(item) {
      this.$confirm('请确认是否将【' + item.name + '】移出我的收藏?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.collection(item.collectionId)

      }).catch(() => {

        return
      });
    },
    //切换页码
    changePage(currentPage) {
      this.currentPage = currentPage
      // 发送请求
      this.getList()
    },
    // //跳转详情
    jump(item) {
      // if (item.action == 1) {
      this.$router.push('/courseDetail?courseId=' + item)
      // }
    },
    // 获取收藏列表
    async getList() {
      this.loading = true
      await myCollection_api({
        current: this.currentPage,
        size: this.pageSize,
        name: this.courseName,
      }).then(res => {
        if (res.code == 200) {
          this.courseList = res.data.records
          this.total = res.data.total
          this.loading = false
        }
      })
    },
  }
}
</script>
<style scoped>
.w100 {
  width : 100%;
}

.collect {
  padding    : 2rem;
  box-sizing : border-box;
  background : #fff;
}

.flex {
  display : flex
}

.flex1 {
  flex : 1;
}

.algin {
  align-items : center
}

.txt_right {
  text-align : right
}

.flex_col {
  flex-direction : column;
}

.p_in_04 {
  padding    : 0.5em 0.5em;
  box-sizing : border-box
}

.p_in_02 {
  padding    : 0.3em;
  box-sizing : border-box
}

.img-box {
  width  : 100%;
  height : 70%;
}

.image {
  display : block;
  width   : 100%;
  height  : 15vh;
}

.card {
  width          : 22%;
  box-shadow     : 1px 1px 12px #eee;
  margin         : 0.5em 1.4em;
  padding-bottom : 10px;
  box-sizing     : border-box;
}

.box {
  width       : 100%;
  display     : flex;
  align-items : center;
  flex-wrap   : wrap;
}

.scang {
  display : flex;
  width   : 1.5em;
  height  : 1.5em;
}

.txt_light {
  color : #666;
}

.m_l_02 {
  margin-left : 0.2em
}

.sty {
  background : #ff5722;
  color      : #fff;
}

.sty1 {
  background : #ffc107;
  color      : #fff;
}

.sty2 {
  background : #9e9e9e;
  color      : #fff;
}

.sty3 {
  background : #009688;
  color      : #fff;
}

.m_tb_04 {
  margin : 0.4em 0;
}

.m_lr_05 {
  margin : 0 0.5em;
}

.f20 {
  font-size : 1.5em;
  color     : #666
}

.f25 {
  font-size : 1.5rem;
  color     : #666
}

.p_lr_05 {
  padding-right : 1.5em;
  padding-left  : 1.5em;
  box-sizing    : border-box;
}

.inputSty {
  border        : 1px solid #999;
  border-radius : 10px;
  display       : block;
  padding       : 0.8rem 1rem;
  box-sizing    : border-box;
  outline       : none;
  width         : 350px
}

.sousuo {
  font-size   : 2rem;
  margin-left : -3rem;
  color       : #999
}

.m_t_08 {
  margin-top : 1.5rem;
}

.flex_center {
  justify-content : center
}

.hidden {
  overflow      : hidden;
  text-overflow : ellipsis;
  white-space   : nowrap
}
</style>
